<template>
    <div class="appContainer">
        <Earth></Earth>
        <Header></Header>
        <div class="statContainer mainTextColor">
            <h1 class="title mainTextColor">{{Store.state.selectedCity}}</h1>
            <CategoryOfCity :selectable="false"></CategoryOfCity>
            <div class="buttons">
                <el-button type="info" @click="gotoIndex" class="btn btnIndex">回到首页</el-button>
                <el-button type="primary" @click="gotoDetail" class="btn">了解更多</el-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import Header from '../../components/app/Header.vue';
import Earth from "@/components/NewEarth/index.vue";
import CategoryOfCity from '@/components/app/CategoryOfCity.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const Store = useStore();
const router = useRouter();
const gotoIndex = () => {
    Store.commit('selectCity','')
    router.push('/app/index')
};
const gotoDetail = () => router.push('/app/areaDetail');
</script>
<style lang="scss" scoped>
.appContainer {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
}

.title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.mainTextColor{
    color: rgba(255, 255, 255, 0.8);
}

.statContainer {
    background: linear-gradient(180deg, rgba(16, 20, 29, 0.88) 67.78%, rgba(16, 20, 29, 0) 100%), rgba(255, 255, 255, 0.06);
    border-radius: 6px;
    width: calc(100% - 32px);
    position: absolute;
    bottom: 40px;
    left: 16px;
    z-index: 19;
    color: rgba(255, 255, 255, 0.8);
    padding: 10px 0 10px 0;
    
    .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

.btn {
    width: 40%;
    width: 134px;
    height: 40px;
    border-radius: 30px;
}

.btnIndex {
    background: rgba(237, 237, 237, 0.21);
}
</style>