<template>
  <nav @click="selectCategory">
    <li v-for="category in categories" :key="category.value">
      <div
      class="categoryBox"
        :class="selectable?(activeCategory==category.value ? 'active' :null) : null"
        :name="category.value"
      >
        <div class="categoryTitle" :name="category.value">{{category.label}}</div>
        <div
          class="count"
          :name="category.value"
        >{{Store.state.cityProjectStatisticInfo[category.value]}}</div>
      </div>
    </li>
  </nav>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { categories } from "../../constants/index.js";
import { useStore } from "vuex";

const props = defineProps({
  selectable: {
    type: Boolean,
    default: false
  }
});

const Store = useStore();

const all = categories[0].value;
const activeCategory = ref(categories[0].value);

const selectCategory = e => {
  if (!props.selectable) return;
  let value = e.target.getAttribute("name");
  activeCategory.value = value;
  if (activeCategory.value == all) {
    // 选中的是所有
    Store.dispatch("unselectCategory", "");
  } else {
    //选中了具体类型
    // let categoryName = categories.filter(item => item.value == value)[0].label;
    Store.dispatch("selectCategory", value);
  }
  Store.commit('setCurrentIndex', 0);
};
</script>
<style lang="scss" scoped>
nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 19;
  background-color: #10141de0;
  padding: 9px;
  margin-left: 16px;
  margin-right: 16px;

  li {
    list-style: none;
    flex: 1;
    text-align: center;
    .categoryBox{
      padding: 5px;
    }
    div.categoryTitle {
      font-size: 12px;
    }

    div.count {
      font-size: 20px;
    }
  }
}

.active {
  background-color: rgba(0, 235, 255, 0.12);

  div {
    color: #00ebff;
  }
}
</style>